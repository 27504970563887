.ready-to-connect-wrapper{
  background-image: url("../../images/reconnect-bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  position: relative;
  z-index: 0;
  min-height: 470px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:before{
    content: url("../../images/home/dots.svg");
    position: absolute;
    bottom: 0;
    left: -150px;
    z-index: -1;
  }
  .ready-right{
    background-color: $Primary-Color;
    border-radius: 8px;

    .ready-custom-select{
      margin: 30px 0;
      background-color: $Light-Primary-Color;
      background-image: url("../../images/select-arrow.svg");
      background-repeat: no-repeat;
      background-position: 95%;
      width: 100%;
      height: 46px;
      display: flex;
      align-items: center;
      border: none;
      padding: 0 10px;
      border-radius: 8px;
      outline: none;
      box-shadow: none;
      -webkit-appearance: none;
      font-family: 'Lato-Regular', sans-serif;
      font-size: 16px;
      line-height: 25px;
      color: $White-Color;

      option{
        background-color: $Light-Primary-Color !important;
        font-family: 'Lato-Regular', sans-serif;
        font-size: 16px;
        line-height: 25px;
        color: $White-Color;
        padding: 5px;
      }

    }

    .get-start-btn{
      background: transparent linear-gradient(97deg, #EF504C 0%, #6333F8 100%) 0% 0% no-repeat padding-box;
      width: 100%;
      height: 46px;
      border-radius: 8px;
      font-family: 'Lato-Bold', sans-serif;
      font-size: 14px;
      line-height: 18px;
      color: $White-Color;
      text-align: center;
      text-transform: uppercase;
    }
  }

  @media screen and (min-width: 768px){
    padding: 50px;
  }
}