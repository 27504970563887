.vedanta-case-study-main{
  /*Banner Start*/
  .vedanta-banner-main{
    position: relative;
    min-height: calc(100vh - 94px);
    background-image: linear-gradient(to right, #ffd5ff, #fbe1ff, #f9ecff, #faf6ff, #ffffff);
    display: flex;
    align-items: center;
    z-index: 1;
    .vedanta-banner-content{
      position: relative;
      z-index: 1;
      h3{
        color: $Shawer-Theme-Color;
        font-size: 20px;
        line-height: 24px;
      }
      h2{
        color: #191536;
        font-size: 32px;
        line-height: 50px;
      }
      .banner-blocks{
        span{
          color: #B9B9B9;
          font-family: 'Lato-Bold', sans-serif;
          font-size: 12px;
          line-height: 15px;
          text-transform: uppercase;
        }
        p{
          color: #000000;
          font-family: 'Lato-Regular', sans-serif;
          font-size: 18px;
          line-height: 23px;
          margin-top: 10px;
        }
      }
    }
  }
  /*Banner End*/

  /*Tabination Start*/
  .vedanta-tabination{
    border-bottom: 2px solid transparent;
    cursor: pointer;
    background-color: #FBFBFB;
    .vedanta-tab-list{
      li{
        @media screen and (min-width: 768px){
          margin: 0 15px;
        }
        a{
          font-family: 'Lato-Bold', sans-serif;
          font-size: 14px;
          line-height: 18px;
          color: #A0A0A0;
          text-transform: uppercase;
          border-radius: 0;
          padding-bottom: 15px;
          &:hover {
            color: $Primary-Color;
          }
        }
        &:hover {
          background: transparent;
          color: $Primary-Color;
          border-bottom: 2px solid $Primary-Color;
        }
        .active{
          background: transparent;
          color: $Primary-Color;
          border-bottom: 2px solid $Primary-Color;
        }
      }
    }

    .vedanta-about-content{
      position: relative;
      z-index: 1;
      &:after{
        content: url("../../../assets/images/caseStudy/vedanta/vedanta-about-ellipse.svg");
        position: absolute;
        left: -55%;
        bottom: -70%;
        z-index: -1;

        @media screen and (max-width: 767px){
          right: 0;
          left: unset;
        }
      }
    }

    .vedanta-challenge-main{
      position: relative;
      z-index: 1;
      &:after{
        content: url("../../../assets/images/caseStudy/vedanta/dots.svg");
        position: absolute;
        right: 0;
        top: -100px;
        z-index: -1;
        @media screen and (max-width: 576px){
          display: none;
        }
      }
      &:before{
        content: url("../../../assets/images/caseStudy/vedanta/outcome-ellipse.png");
        position: absolute;
        left: -30%;
        top: 30%;
        z-index: -1;
        @media screen and (max-width: 576px){
          display: none;
        }
      }
    }

  }
  /*Tabination End*/

  /*Testimonial Start*/
  .vedanta-testimonilas-slider-main{
    position: relative;
    &:before{
      content: url("../../images/caseStudy/vedanta/testi-before.svg");
      position: absolute;
      left: 400px;
      bottom: 0;
      @media screen and (max-width: 767px){
        display: none;
      }
    }
    .vedanta-slider-content{
      .vedanta-testi-block{
        min-height: 380px;
        width: 360px !important;
        padding: 20px;
        border-radius: 20px;
        display: flex !important;
        flex-wrap: wrap;
        .testi-img{
          height: 57px;
          width: 57px;
          border-radius: 50%;
          object-fit: cover;
          object-position: top center;
        }
      }
      .bg-purple{
        background-color: #f4e6ff;
      }
      .bg-pink{
        background-color: #ffe0f1;
      }
    }

    .slick-slide > div {
      margin: 0 10px;
    }
    .slick-next:before, .slick-prev:before{
      display: none !important;
    }

    .prev-arrow{
      position: absolute;
      bottom: 40% !important;
      top: unset !important;
      left: -440px !important;
      @media screen and (max-width: 1024px){
        left: 50px !important;
        bottom: -80px !important;
      }
    }
    .next-arrow{
      position: absolute;
      bottom: 40% !important;
      top: unset !important;
      left: -380px !important;
      @media screen and (max-width: 1024px){
        left: 100px !important;
        bottom: -80px !important;
      }
    }
    .custom-slick-prev{
      background: $White-Color;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      opacity: 1;
      height: 40px;
      width: 40px;
      border-radius: 50%;
      box-shadow: 0 0 26px 0 rgba(0, 0, 0, 0.13);
      img{
        width: 20px;
      }
      img:first-child{
        display: inline-block;
      }
      img:last-child{
        display: none;
      }
      &:hover{
        img:first-child{
          display: none;
        }
        img:last-child{
          display: inline-block;
        }
      }
    }
    .custom-slick-next{
      background: $White-Color;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      opacity: 1;
      height: 40px;
      width: 40px;
      border-radius: 50%;
      box-shadow: 0 0 26px 0 rgba(0, 0, 0, 0.13);
      img{
        width: 20px;
      }
      img:first-child{
        display: inline-block;
      }
      img:last-child{
        display: none;
      }
      &:hover{
        img:first-child{
          display: none;
        }
        img:last-child{
          display: inline-block;
        }
      }
    }

  }
  /*Testimonial End*/

}
