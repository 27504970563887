.button-main {
  background-color: $Primary-Color;
  min-width: 150px;
  padding: 5px 20px;
  font-size: 16px;
  line-height: 36px;
  font-family: 'Lato-Bold', sans-serif;
  outline: none;
  border: 1px solid $Primary-Color;
  color: $White-Color;
  a {
    color: $White-Color;
  }

  &:hover {
    a {
      color: $Primary-Color;
    }
  }

  @media screen and (max-width: 576px) {
    font-size: 12px;
    line-height: 14px;
    padding: 10px 20px;
  }
}
