.industry-main {
  position: relative;
  .industry-banner-img {
    position: absolute;
    right: -135px;
    top: 0;
    border-radius: 50%;
    z-index: 1;

    @media screen and (max-width: 576px){
      right: 0;
      top: -15px;
      z-index: -1;
    }
  }
  .industry-banner {
    min-height: calc(100vh - 94px);
    position: relative;
    align-items: center;
    display: flex;
  }
  .image-tab {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    li {
      list-style-type: none;
    }
    li:nth-child(even) {
      margin-bottom: 100px;
    }
  }
}
