.help-guide-detail-main{
  .help-guide-detail-left{
    .custom-nav-pills{
@media screen and (max-width: 767px){
  display: block;
  white-space: nowrap;
  overflow: auto;
}
      a{
        font-family: 'Lato-Regular', sans-serif;
        font-size: 20px;
        line-height: 24px;
        color: $Light-Secondary-Color;
        border-left: 3px solid transparent;
        border-radius: 0;
        margin-bottom: 15px;
        @media screen and (max-width: 767px){
          font-size: 16px;
          line-height: 16px;
          text-transform: uppercase;
          border-bottom: 3px solid transparent;
          border-left: none;
        }
        @media screen and (max-width: 991px){
          display: inline-block;
          padding: 10px;
          border-left: none;
          font-size: 16px;
        }
      }
      .active{
        font-family: 'Lato-Regular', sans-serif;
        background-color: transparent;
        border-left: 3px solid $Primary-Color;
        color: $Black-Color;
        @media screen and (max-width: 991px){
          display: inline-block;
          border-bottom: 3px solid $Primary-Color;
          border-left: none;
          padding: 10px;
        }
      }
    }
  }
  .help-guide-detail-right{
    .section-before-you-begin{
      position: relative;

      &:before{
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        height: 1px;
        width: 60%;
        background-color: #EBEAEA;
      }
      &:after{
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        height: 1px;
        width: 60%;
        background-color: #EBEAEA;
      }
      ul{
        li{
          position: relative;
          padding-left: 30px;
          font-family: 'Lato-Regular', sans-serif;
          font-size: 14px;
          line-height: 25px;
          color: $Light-Primary-Color;
          &:before{
            content: '';
            position: absolute;
            left: 0;
            top: 10px;
            height: 8px;
            width: 8px;
            background-color: $Black-Color;
            border-radius: 50%;
          }
        }
      }

    }

    .section-technology{
      .tech-box{
        box-shadow: 0px 3px 36px #00000019;
        border-radius: 13px;
        background-color: $White-Color;
      }

      .help-box{
        border: 1px solid #EBEAEA;
        border-radius: 10px;
        @media screen and (min-width: 768px){
          margin-top: 120px;
        }
        button{
          width: 92px;
          display: inline-block;
        }
      }
    }
  }

}