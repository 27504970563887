.rectangle {
        display:inline-grid !important;
        width: 57px;
        height: 3px;
        margin: 10px 8px 10px 0;
        background-color: #191536;
}

.imge{
  margin-top:-166px;
}
.bgGreyColor{
      background:#f4f4f4 !important;
}

.bgWhiteColor{
      background:#FFFFFF !important;
}

.value-color{
   background-color: #f4f4f4 !important;
}
.midigiworld-study-main{
  background-color: #EEF2F7;

  /*Banner Start*/
  .midigiworld-challenge-main{
    position: relative;
    z-index: 1;
    background-color: #FFFFFF;
    &:after{
      content: url("../../../assets/images/caseStudy/midigiworld/half-circle.svg");
      position: absolute;
      top:16%;
      left: -8%;
      bottom: 0;
      z-index: -10;
      @media screen and (max-width: 767px){
        display: none;
      }
    }
    }
  
  .midigiworld-banner-main{
    position: relative;
    z-index: 1;
    background-color: #fefafa;
    &:after{
      content: url("../../../assets/images/caseStudy/midigiworld/blue-dotted-circle.svg");
      position: absolute;
      top:4%;
      left: -10%;
      bottom: 0;
      z-index: 0;
      @media screen and (max-width: 767px){
        display: none;
      }
    }
    .midigiworld-banner-content{
      position: relative;
      z-index: 1;
      h3{
        color: $Shawer-Theme-Color;
        font-size: 20px;
        line-height: 24px;
      }
      h2{
        color: #191536;
        font-size: 28px;
        line-height: 50px;
      }
      .banner-blocks{
        span{
          color: #B9B9B9;
          font-family: 'Lato-Bold', sans-serif;
          font-size: 12px;
          line-height: 15px;
          text-transform: uppercase;
        }
        p{
          color: #000000;
          font-family: 'Lato-Regular', sans-serif;
          font-size: 18px;
          line-height: 23px;
          margin-top: 10px;
        }
      }
    }
  }
  /*Banner End*/

  /*Tabination Start*/
  .midigiworld-tabination{
 
    
    .bgcolor{
       background: #fbfbfb;
    }
    .midigiworld-tab-list{
      li{
        border-bottom: 2px solid transparent;
        cursor: pointer;
        @media screen and (min-width: 768px){
          margin: 0 15px;
        }
        a{
          font-family: 'Lato-Bold', sans-serif;
          font-size: 14px;
          line-height: 18px;
          color: #A0A0A0;
          text-transform: uppercase;
          border-radius: 0;
          padding-bottom: 15px;
          &:hover {
            color: $Argo-Theme-Color !important;
          }
        }
        &:hover {
          background: transparent;
          color: $Argo-Theme-Color !important;
          border-bottom: 2px solid $Argo-Theme-Color;
        }
        .active{
          background: transparent;
          color: $Argo-Theme-Color;
          border-bottom: 2px solid $Argo-Theme-Color;
        }
      }
    }
    .midigiworld-outcome-main-two{
      position: relative;
      z-index: 1;
      &:after{
        content: url("../../../assets/images/caseStudy/midigiworld/blue-dotted-circle.svg");
        position: absolute;
        right: 50px;
        top: 85%;
        bottom: 0;
        z-index: -1;
        @media screen and (max-width: 767px){
          display: none;
        }
      }
    }
    .midigiworld-outcome-main-three{
      position: relative;
      z-index: 1;
     &:after{
        content: url("../../../assets/images/caseStudy/midigiworld/blue-dotted-circle.svg");
        position: absolute;
        right: 50px;
        top:-30%;
        bottom: 0;
        z-index: -1;
        @media screen and (max-width: 767px){
          display: none;
        }
      }
    }
    .midigiworld-outcome-main{
      position: relative;
      z-index: 1;
       &:after{
        content: url("../../../assets/images/caseStudy/midigiworld/multiple-circles.svg");
        position: absolute;
        top:25%;
        left: -18%;
        bottom: 0;
        z-index: -10;
        @media screen and (max-width: 767px){
          display: none;
        }
      }
     
      .outcome-value-block-main{
        background: #F4F4F4;
        border-radius: 30px;
        position: relative;
        z-index: 0;
        &:after{
          content: url("../../../assets/images/caseStudy/midigiworld/blue-dotted-circle.svg");
          position: absolute;
          right: -25%;
          top:14%;
          bottom: 0;
          z-index: -1;
          @media screen and (max-width: 767px){
            display: none;
          }
        }
        .outcome-image{
           &:after{
            content: url("../../../assets/images/caseStudy/midigiworld/blue-dotted-circle.svg");
            position: absolute;
            right: 0%;
            top:40%;
            bottom: 0;
            z-index: -1;
            @media screen and (max-width: 767px){
              display: none;
            }
          }
        }
        .value-card{
          background-color: $White-Color;
          box-shadow: 0px 6px 96px #00000021;
          border-radius: 20px;
          min-height: 230px;
          display: flex;
          align-items: center;
          justify-content: start;
          h2{
            color: $Shawer-Theme-Color;
            font-size: 30px;
          }
        }
      }
    }
    .midigiworld-value{
      position: relative;
      z-index: 1;
     
      .outcome-value-block-main{
        background: #F4F4F4;
        border-radius: 30px;
        position: relative;
        z-index: 0;
        &:after{
          content: url("../../../assets/images/caseStudy/midigiworld/blue-dotted-circle.svg");
          position: absolute;
          right: -25%;
          top:14%;
          bottom: 0;
          z-index: -1;
          @media screen and (max-width: 767px){
            display: none;
          }
        }
        .outcome-image{
           &:after{
            content: url("../../../assets/images/caseStudy/midigiworld/blue-dotted-circle.svg");
            position: absolute;
            right: 0%;
            top:40%;
            bottom: 0;
            z-index: -1;
            @media screen and (max-width: 767px){
              display: none;
            }
          }
        }
        .value-card{
          background-color: $White-Color;
          box-shadow: 0px 6px 96px #00000021;
          border-radius: 20px;
          min-height: 230px;
          display: flex;
          align-items: center;
          justify-content: start;
          h2{
            color: $Shawer-Theme-Color;
            font-size: 30px;
          }
        }
      }
    }

  }
  /*Tabination End*/
}

@media screen and (max-width: 768px) {
  .imge {
    margin-top: -50px;
  }
}

@media screen and (max-width: 600px) {
  .tempPadding-45 {
    padding: 0px 45px;
  }
}