@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&display=swap');

@font-face {
  font-family: 'Lato-Light';
  src: url('../fonts/Lato/Lato-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato-Regular';
  src: url('../fonts/Lato/Lato-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato-Bold';
  src: url('../fonts/Lato/Lato-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato-Black';
  src: url('../fonts/Lato/Lato-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

.f-w-100 {
  font-weight: 100;
}

.f-w-300 {
  font-weight: 300;
}

.f-w-400 {
  font-weight: 400;
}

.f-w-700 {
  font-weight: 700;
}

.f-w-900 {
  font-weight: 900;
}

.f-lat-lig {
  font-family: 'Lato-Light', sans-serif;
}

.f-lat-reg {
  font-family: 'Lato-Regular', sans-serif;
}

.f-lat-bol {
  font-family: 'Lato-Bold', sans-serif;
}

.f-lat-bla {
  font-family: 'Lato-Black', sans-serif !important;
}

.f-14 {
  font-size: 14px;
  line-height: 25px;
}

.f-16 {
  font-size: 16px;
  line-height: 22px;
  @media screen and (max-width: 768px){
    font-size: 12px;
  }
}

.f-18 {
  font-size: 18px;
  line-height: 27px;
  @media screen and (max-width: 768px){
    font-size: 16px;
  }
}

.f-20 {
  font-size: 20px;
  line-height: 30px;
  @media screen and (max-width: 768px){
    font-size: 14px;
    line-height: 21px;
  }
}

.f-24 {
  font-size: 24px;
  line-height: 30px;
  @media screen and (max-width: 768px){
    font-size: 18px;
  }
}

.f-26 {
  font-size: 26px;
  line-height: 32px;
  @media screen and (max-width: 768px){
    font-size: 18px;
    line-height: 24px;
  }
}

.f-28 {
  font-size: 28px;
  line-height: 52px;
  @media screen and (max-width: 768px){
    font-size: 18px;
    line-height: 24px;
  }
}

.f-34{
  font-size: 34px;
  line-height: 41px;
  @media screen and (max-width: 768px){
    font-size: 26px;
    line-height: 30px;
  }
}

.f-36{
  font-size: 36px;
  line-height: 44px;
  @media screen and (max-width: 768px){
    font-size: 24px;
    line-height: 30px;
  }
}

.f-38{
  font-size: 38px;
  line-height: 46px;
  @media screen and (max-width: 768px){
    font-size: 24px;
    line-height: 30px;
  }
}

.f-40{
  font-size: 40px;
  line-height: 48px;
  @media screen and (max-width: 768px){
    font-size: 24px;
    line-height: 30px;
  }
}

.f-42 {
  font-size: 42px;
  line-height: 50px;
  @media screen and (max-width: 768px){
    font-size: 24px;
    line-height: 30px;
  }
}


/*Fonts End*/


body, html {
  width: 100%;
  height: 100%;
  scroll-behavior: smooth;
  font-family: 'Lato-Light', sans-serif;
}

body {
  overflow-x: hidden;
}

body::-webkit-scrollbar {
  width: 8px;
}

.help-list::-webkit-scrollbar {
  width: 8px;
}

.help-list::-webkit-scrollbar-thumb{
  background: $Light-Primary-Color;
  border-radius: 10px;
}

.our-product-card-slider-main::-webkit-scrollbar {
  width: 0;
  height: 0;
}

body::-webkit-scrollbar-thumb {
  background: $Light-Primary-Color;
  border-radius: 10px;
}

::selection {
  color: $Primary-Color;
  background: $Secondary-Color;
}


* {
  margin: 0;
  padding: 0;
}

/*Common Elements Start*/
.pt-88{
  padding-top: 88px !important;
}

.cp{
  cursor: pointer;
}

a {
  cursor: pointer;
  text-decoration: none !important;
}

.cursor-text{
  cursor: text;
}

.text-light-white {
  color: $Light-White-Color;
}

.c-btn {
  min-width: 138px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $Light-Primary-Color;
  border: 2px solid $Light-Primary-Color;
  border-radius: 10px;
  font-size: 14px;
  line-height: 17px;
  font-family: 'Lato-Bold';
  color: $White-Color;
  text-transform: uppercase;
  transition: 0.4s ease all;
  &:hover{
    color: $Light-Primary-Color !important;
    background-color: $White-Color !important;
    border: 2px solid $Light-Primary-Color !important;
  }
}

.text-primary {
  color: $Primary-Color !important;
}

.text-light-primary {
  color: $Light-Primary-Color !important;
}

.text-secondary {
  color: $Secondary-Color !important;
}

.text-dark-secondary {
  color: $Dark-Secondary-Color !important;
}

.text-orange {
  color: $Orange-Color;
}

.text-dark-orange {
  color: $Dark-Orange-Color !important;
}

.text-light-purple{
  color: $Light-Purple-Color;
}

.text-black{
  color: $Black-Color;
}

.bg-primary {
  background-color: $Primary-Color !important;
}

.bg-dark-primary {
  background-color: $Primary-Color !important;
}

.bg-white {
  background-color: $White-Color !important;
}

.bg-sky{
  background-color: $Sky-Color;
}

.cursor-pointer {
  cursor: pointer;
}

.toast-notification {
  background-color: $White-Color;
  color: $Primary-Color;
  padding: 20px;
}


.modal-content{
  background-color: transparent;
  border: none;
  .modal-body{
    video{
      outline: none;
      margin: 0 auto;

      @media screen and (max-width: 576px){
        width: 320px;
      }
    }
  }
}

textarea{
  border-radius: 4px;
  border: solid 1px #c8ccd0;
  resize: none;
  width: 100%;
  padding: 15px;
  outline: none;
  font-family: 'Lato-Regular' , sans-serif;
  font-size: 16px;
  line-height: 20px;
}
/*Common Elements End*/
