.question-page-main {
  background-color: $Light-Grayish-Blue;
  min-height: 100vh;

  .question-left-section{
    .stepper {
      margin-top: 20px;
      font-size: 14px;
      line-height: 16px;
      color: $Primary-Color;
      font-family: 'Lato-Bold', sans-serif;

      p {
        margin-bottom: 10px;
      }

      .progress {
        border-radius: 8px;
        height: 10px;
        width: 30vh;

        .progress-bar {
          width: 50%;
          border-radius: 8px;
          background-image: linear-gradient(to right, #e14d5d, #e61f76, #dc009b, #ba00c8, #6333f8);
        }
      }
    }

    .input-style {
      width: 100%;
      height: 50px;
      border: 1px solid $Light-Grey-Question-Box;
      border-radius: 8px;
      padding: 5px 15px;
      font-family: 'Lato-Regular', sans-serif;
      font-size: 18px;
      color: $Primary-Color;

      &:focus {
        outline: none;
      }

      &::placeholder {
        color: $Light-Grey-Question-Box;
      }
    }

    h3 {
      font-size: 27px;
      line-height: 49px;
      color: $Primary-Color;
      font-family: 'Lato-Bold', sans-serif;
      margin-bottom: 0;
    }

    p {
      font-size: 18px;
      line-height: 20px;
      font-family: 'Lato-Regular', sans-serif;
      color: $Dark-Secondary-Color;
    }

    .question-1 {

    }

    .question-2 {

    }

    .question-3 {

    }

    .question-4 {
      .question-channels-list{
        li{
          display: inline-flex;
          justify-content: center;
          align-items: center;
          min-width: 120px;
          height: 40px;
          margin-right: 15px;
          margin-bottom: 15px;
          font-family: 'Lato-Bold', sans-serif;
          text-transform: uppercase;
          font-size: 14px;
          line-height: 16px;
          color: $Primary-Color;
          border-radius: 20px;
          border: 1px solid $Light-Grey-Question-Box;
          background-color: $White-Color;
          span{
            padding: 0 30px;
          }

        }
        .active{
          background-color: #E5DDFA !important;
          padding: 0;
          border: 1px solid transparent;
          border-radius: 20px;
          background-image: linear-gradient( #E5DDFA,  #E5DDFA), radial-gradient(circle at bottom left, #EF504C, #6333F8);
          background-origin: border-box;
          background-clip: content-box, border-box;
        }
      }
    }

    .question-5 {

    }

    .question-6 {

    }

    .question-7 {
      .q-seven-form{
        label{
          font-family: 'Lato-Bold', sans-serif;
          color: $Dark-Secondary-Color;
        }
        input,select{
          font-family: 'Lato-Regular', sans-serif;
          border-radius: 4px;
          border: 1px solid #C8CCD0;
          height: 44px;
          &:focus{
            box-shadow: none;
          }
          &::placeholder{
            color: #A7A2A2;
          }
        }
      }
    }
  }

  .question-right-section{
    background: url("../../images/question/right-shape-question.svg") , transparent linear-gradient(154deg, #EF504C 0%, #6333F8 100%) 0% 0% no-repeat padding-box;
    background-repeat: no-repeat;
    min-height: 100vh;
    /*display: flex;
    align-items: center;
    justify-content: center;*/
    .splash-steppper{
      position: relative;
      li{
        position: relative;
        &:after{
          content: '';
          position: absolute;
          bottom: 0;
          left: 10px;
          height: 70%;
          width: 2px;
          border: 1px dashed $White-Color;
        }
        &:last-child{
          &:after{
            display: none;
          }
        }
      }
    }
  }

  .need-help-wrapper{
    width: 469px;
    position: fixed;
    top: 0;
    bottom: 0;
    box-shadow: 1px 12px 33px 0 rgba(0, 0, 0, 0.12);
    background-color: #f5f9fc;
    transition: 0.5s ease all;
    .need-help-title{
      border-bottom: 1px solid #979797;
    }

    .help-list{
      overflow-y: auto;
      height: calc(100vh - 120px);
    }
  }

  .need-help-wrapper-active {
    right: 0;
  }

  .need-help-wrapper-inactive {
    right: -500px;
  }

  @media screen and (max-width: 576px){
    .need-help-wrapper{
      width: auto;
    }
  }
}
