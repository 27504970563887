.help-guide-main{

  /*Banner Start*/
  .help-guide-banner-main{
  /*padding: 150px 0;*/
  /*background-color: $Primary-Color;*/
  background-image: url("../../images/helpGuide/help-guide-bannner-shape.svg"), linear-gradient(61deg, #2b2748, #1d183c 28%, $Primary-Color);;
  background-repeat: no-repeat;
  background-position: -25% 80%;
}
  /*Banner End*/

  /*Browse Category Start*/
  .browse-categories-main{
    .browse-card-main{
      min-height: 134px;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0 3px 36px 0 rgba(0, 0, 0, 0.1);
      border-radius: 13px;
      transition: 0.4s ease all;
      &:hover{
        transform: translateY(-5px);
      }
    }

    .browse-category-card{
      min-height: 320px;
      display: flex;
      flex-wrap: wrap;
      box-shadow: 0 3px 36px 0 rgba(0, 0, 0, 0.1);
      border-radius: 13px;
      transition: 0.4s ease all;
      &:hover{
        transform: translateY(-5px);
      }
    }
  }
  /*Browse Category End*/

  /*Contact Us Start*/
  .help-guide-contact-main{
    background-color: $Sky-Color;
    .help-guide-contact-form-main{
      .input-style{
        border-radius: 4px;
        border: solid 1px #c8ccd0;
        height: 48px;
        box-shadow: none;
        //margin-bottom: 20px;
        font-family: 'Lato-Regular' , sans-serif;
        font-size: 16px;
        line-height: 13px;
      }
      input::placeholder, textarea::placeholder {
        font-family: 'Lato-Regular' , sans-serif;
        font-size: 16px;
        line-height: 13px;
        color: #a7a2a2;
      }
      textarea{
        border-radius: 4px;
        border: solid 1px #c8ccd0;
        resize: none;
        width: 100%;
        padding: 15px;
        //margin-bottom: 20px;
        outline: none;
        font-family: 'Lato-Regular' , sans-serif;
        font-size: 16px;
        line-height: 13px;
      }
    }
  }
  /*Contact Us End*/

}
