/*Home Page Start*/
.home-page-main {
  position: relative;
  background-color: $Primary-Color;
  z-index: 1;

  &:after{
    content: url("../../images/home/home-banner-shape.svg");
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
  }

  .section-title {
    color: $White-Color;

    span {
      font-size: 18px;
      line-height: 38px;
      font-family: 'Lato-Bold', sans-serif;
      text-transform: uppercase;
    }

    h2 {
      font-size: 38px;
      line-height: 40px;
      font-family: 'Lato-Bold', sans-serif;
      @media screen and (max-width: 768px){
        font-size: 24px;
        line-height: 24px;
      }
    }

    p {
      font-size: 18px;
      line-height: 27px;
      font-family: 'Lato-Regular', sans-serif;
      @media screen and (max-width: 768px){
        font-size: 14px;
        line-height: 21px;
      }
    }
  }

  /*Banner Start*/
  .home-banner-main {
    /*background-color: linear-gradient(61deg, #2b2748, #1d183c 28%, $Primary-Color);*/
    /*background-image: url("../../images/home/home-banner-shape.svg");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: right top;*/
    min-height: calc(100vh - 89px);
    display: flex;
    align-items: center;

    .home-banner-right{
      .banner-ellipse-animation{
        .play-icon{
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50% , -50%);
          cursor: pointer;
        }
      }
    }
  }

  /*Banner End*/

  /*Industries Start*/
  .home-industries-main {
    background-color: $Primary-Color;
    position: relative;
    z-index: 0;
    &:after{
      content: url("../../images/home/dots.svg");
      position: absolute;
      bottom: -50px;
      right: 0;
      z-index: -1;
    }

    &:before{
      content: url("../../images/home/semi-ellipse-shape.svg");
      position: absolute;
      bottom: -200px;
      left: -100px;
    }
  }

  /*Industries End*/

  /*Our Services Start*/
  .our-services-main {
    .service-line {
      height: 2px;
      width: 55px;
      background-color: $White-Color;
    }

    .service-block {
      .service-number {
        position: absolute;
        top: 0;
        left: 0;
      }

      h5 {
        position: relative;
        padding-bottom: 10px;

        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          height: 1px;
          width: 50px;
          background-color: $White-Color;

        }
      }
    }
  }

  /*Our Services End*/

  /*Our Product Start*/
  .home-our-product-main{
    background: transparent linear-gradient(51deg, #231E48 0%, #191536 100%) 0% 0% no-repeat padding-box;
    .our-product-card-slider-main{
      overflow: auto;
      .build-slider-card{
        padding-right: 15px;
        color: $Light-White-Color;
        transition: 0.4s ease all;
        .line{
          img:first-child{
            display: inline-block;
          }
          img:last-child{
            display: none;
          }
        }
        &:hover{
          color: $White-Color;
          .line{
            img:first-child{
              display: none;
            }
            img:last-child{

              display: inline-block;
            }
          }
        }
      }
    }
  }
  /*Our Product End*/

  /*Blog Start*/
  .blog-card-main {
    background-color: $Primary-Color;

    .blog-card {
      .blog-card-content {
        p {
          color: $White-Color !important;
        }
      }
    }
  }

  /*Blog End*/

@media screen and (max-width: 1024px){
  .home-banner-main {
    min-height: auto;
  }
}
}

/*Home Page End*/
