$White-Color: #ffffff;
$Light-White-Color: rgba(255, 255, 255, 0.6);
$Black-Color: #000000;
$Primary-Color: #191536;
$Light-Primary-Color: #332c5f;
$Light-Secondary-Color: #A3A3A3;
$Secondary-Color: #898989;
$Dark-Secondary-Color: #525252;
$Orange-Color: #ef504c;
$Dark-Orange-Color: #F47721;
$Light-Grey-Color: #45484d;
$Sky-Color: #f6f8fa;
$Dark-Grayish-Navy-Color: #393a45;
$Input-Background-Color: #fafcfd;
$Input-Placeholder-Color: #a7a2a2;
$Purple-Color: #4300ad;
$Light-Purple-Color: #8324E3;
$Dark-Pink-Color: #af3a8b;
$Matterhorn-Color: #4d4a4a;
$Argo-Theme-Color: #DF1F26;
$Iskcon-Theme-Color: #EF504C;
$Shawer-Theme-Color: #0051A9;
$Light-Grayish-Blue: #f9fafb;
$Light-Grey-Question-Box: #d6d6d6;
$Ship-Grey: #41404a;

