.argo-case-study-main{
  background-color: #EEF2F7;

  /*Banner Start*/
  .argo-banner-main{
    position: relative;
    z-index: 1;
    background-color: #fefafa;
    &:after{
      content: url("../../../assets/images/caseStudy/argo/argo-banner-dots.svg");
      position: absolute;
      left: -10%;
      bottom: 0;
      z-index: 0;
      @media screen and (max-width: 767px){
        display: none;
      }
    }
    .argo-banner-content{
      position: relative;
      z-index: 1;
      h3{
        color: $Argo-Theme-Color;
        font-size: 20px;
        line-height: 24px;
      }
      h2{
        color: #191536;
        font-size: 32px;
        line-height: 50px;
      }
      .banner-blocks{
        span{
          color: #B9B9B9;
          font-family: 'Lato-Bold', sans-serif;
          font-size: 12px;
          line-height: 15px;
          text-transform: uppercase;
        }
        p{
          color: #000000;
          font-family: 'Lato-Regular', sans-serif;
          font-size: 18px;
          line-height: 23px;
          margin-top: 10px;
        }
      }
    }
  }
  /*Banner End*/

  /*Tabination Start*/
  .argo-tabination{
    .argo-tab-list{
      li{
        border-bottom: 2px solid transparent;
        cursor: pointer;
        @media screen and (min-width: 768px){
          margin: 0 15px;
        }
        a{
          font-family: 'Lato-Bold', sans-serif;
          font-size: 14px;
          line-height: 18px;
          color: #A0A0A0;
          text-transform: uppercase;
          border-radius: 0;
          padding-bottom: 15px;
          &:hover {
            color: $Argo-Theme-Color !important;
          }
        }
        &:hover {
          background: transparent;
          color: $Argo-Theme-Color !important;
          border-bottom: 2px solid $Argo-Theme-Color;
        }
        .active{
          background: transparent;
          color: $Argo-Theme-Color;
          border-bottom: 2px solid $Argo-Theme-Color;
        }
      }
    }

    .argo-challenge-main{
      position: relative;
      z-index: 0;
      &:after{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 70%;
        height: 100%;
        margin: 0 auto;
        background: #F5F5F5;
        z-index: -1;
        border-radius: 30px;
      }
    }

    .argo-outcome-main{
      position: relative;
      z-index: 1;
      .outcome-value-block-main{
        background: #F4F4F4;
        border-radius: 30px;
        position: relative;
        z-index: 0;


        .value-card{
          background-color: $White-Color;
          box-shadow: 0px 6px 96px #00000021;
          border-radius: 20px;
          min-height: 200px;
          display: flex;
          align-items: center;
          justify-content: start;

        }
      }

      &:after{
        content: url("../../../assets/images/caseStudy/argo/argo-banner-dots.svg");
        position: absolute;
        right: 50px;
        bottom: 300px;
        z-index: -1;
        @media screen and (max-width: 767px){
          display: none;
        }
      }
    }

    .argo-happy-client-main{
      position: relative;
      background-image: url("../../../assets/images/caseStudy/argo/argo-knote-shape.svg");
      background-repeat: no-repeat;
      background-position: -150px;
    }
  }
  /*Tabination End*/
}
