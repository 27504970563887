/*Header Start*/
.header-main {
  position: fixed;
  z-index: 9;
  width: 100%;
  top: 0;
  left: 0;

  &.mentalHealth {
    background: transparent !important;
  }
  .text-blue{
    color: #332c5f !important;
  }

  .header-wrapper {
    position: relative;

    .navbar {
      position: relative;
      z-index: 1;

      .navigational-links {
        li {
          margin: 0 20px;

          a {
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            font-family: 'Lato-Regular', sans-serif;
            text-transform: uppercase;
          }

          &:last-child {
            margin-right: 0;
          }
        }

        .nav-item {
          .nav-link:hover ~ .mega-menu-main {
            background-color: $Light-Primary-Color;
            display: inline-block !important;
            opacity: 1;
            z-index: 5;
            transition: all 0.3s ease 0s;
            visibility: unset;
          }

          .mega-menu-main {
            width: 100%;
            background-color: $Light-Primary-Color;
            top: 60px;
            display: none !important;
            padding: 30px;
            position: absolute;
            left: 0;
            right: 0;
            transition: all 0.3s ease 0s;
            border-radius: 5px;

            &:after {
              content: '';
              position: absolute;
              top: -15px;
              right: 27%;
              z-index: 9;
              width: 0;
              height: 0;
              border-left: 15px solid transparent;
              border-right: 15px solid transparent;
              border-bottom: 15px solid $Light-Primary-Color;
            }

            &:hover {
              background-color: $Light-Primary-Color;
              display: inline-block !important;
              opacity: 1;
              z-index: 5;
              transition: all 0.3s ease 0s;
              visibility: unset;
            }
          }
        }
      }
    }


  }



  @media screen and (max-width: 991px) {
    li {
      margin: 0 !important;
    }
  }

  @media screen and (max-width: 1024px) {
    .navigational-links {
      li {
        margin: 0;
      }
    }
  }
}

/*Header End*/


/*Mobile Header Start*/
.responsive-sidebar-header{
  position: fixed;
  z-index: 9;
  top: 0;
  bottom: 0;
  width: 100%;
  box-shadow: 1px 12px 33px 0 rgba(0, 0, 0, 0.12);
  background-color: $Primary-Color;
  transition: 0.5s ease all;

  .mobile-menu-list{
    height: calc(100vh - 50px);
    overflow-y: scroll;
    li{
      position: relative;
      font-family: 'Lato-Regular', sans-serif;
      font-size: 18px;
      line-height: 20px;
      color: $White-Color;
      padding: 20px 0;
      border-bottom: 1px solid rgba(251,251,251,0.3);
      a {
        color: $White-Color;
      }
      &:last-child{
        border: none;
      }

      .resource-block{
        position: absolute;
        top: 60px;
        left: 0;
        width: 100%;
        padding: 15px 0;
        min-height: 200px;
        background: $Light-Primary-Color;
        display: none;
        overflow-y: scroll;
        z-index: 1;
      }

      &:hover{
        .resource-block{
          display: block;
        }
      }
    }
  }
}

.responsive-sidebar-header-active {
  left: 0;
}

.responsive-sidebar-header-inactive {
  left: -100%;
}
/*Mobile Header End*/

