.mental-health-case-study-main{
    /*Banner Start*/
    .mental-health-banner-main{
      position: relative;
      min-height: calc(100vh - 94px);
    //   background-image: url("../../../assets/images/caseStudy/mentalhealth/banner-screen.png");
      background-repeat: no-repeat;
      background-position: top right;
      background-size: contain;
      display: flex;
      align-items: center;
      z-index: 1;
      .bannerImg {
          margin-top: -1rem;
      }
      .mental-health-banner-content{
        position: relative;
        z-index: 1;
        h3{
          color: $Iskcon-Theme-Color;
          font-size: 20px;
          line-height: 24px;
        }
        h2{
          color: #191536;
          font-size: 32px;
          line-height: 50px;
        }
        &:before{
            content: url("../../../assets/images/caseStudy/mentalhealth/dot-circle.png");
            position: absolute;
            left: -36%;
            top: -220px;
            z-index: -1;
          }

        .banner-blocks{
          span{
            color: #B9B9B9;
            font-family: 'Lato-Bold', sans-serif;
            font-size: 12px;
            line-height: 15px;
            text-transform: uppercase;
            margin-bottom: 10px;
          }
          p{
            font-family: Lato;
            font-size: 19px;
            font-weight: 500;
            line-height: 1.21;
            color: #000;
          }
        }
      }
    }
    /*Banner End*/
  
    /*Tabination Start*/
    .mental-health-tabination{
      background: #fbfbfb !important;
      .mental-health-tab-list{
        li{
          border-bottom: 2px solid transparent;
          cursor: pointer;
          @media screen and (min-width: 768px){
            margin: 0 15px;
          }
          a{
            font-family: 'Lato-Bold', sans-serif;
            font-size: 14px;
            line-height: 18px;
            color: #A0A0A0;
            text-transform: uppercase;
            border-radius: 0;
            padding-bottom: 15px;
            &:hover {
              color: #191536;
            }
          }
          &:hover {
            background: transparent;
            color: #191536;
            border-bottom: 2px solid #191536;
          }
          .active{
            background: transparent;
            color: $Iskcon-Theme-Color;
            border-bottom: 2px solid $Iskcon-Theme-Color;
          }
        }
      }
  
      .iskcon-about-content{
        position: relative;
        z-index: 1;
        // &:before{
        //   content: url("../../../assets/images/caseStudy/iskcon/iskcon-about-ellipse.svg");
        //   position: absolute;
        //   left: -26%;
        //   top: -50px;
        // }
        // &:after{
        //   content: url("../../../assets/images/caseStudy/iskcon/iskcon-about-semi-ellipse.svg");
        //   position: absolute;
        //   right: -26%;
        //   top: -50px;
        //   z-index: -1;
        //   @media screen and (max-width: 767px){
        //     right: 0;
        //   }
        // }
      }
  
      .mental-health-challenge-main{
        position: relative;
        z-index: 2;
        &:before{
            content: url("../../../assets/images/caseStudy/mentalhealth/dot-circle-2.png");
            position: absolute;
            left: -19%;
            z-index: -9;
            top: 22%;
          }
        .challenge-header{
          font-family: Lato;
          font-size: 18px;
          font-weight: 900;
          font-stretch: normal;
          font-style: normal;
          line-height: 2.11;
          letter-spacing: normal;
          text-align: left;
          color: #898989;
        }
        .c-container {
            display: flex;
            justify-content: space-around;
            .c-div{
                min-width: 40%;
                background: white;
                z-index: 9;
                margin-top: -60px;
                padding: 2rem 1rem 3rem 1rem;
                border-radius: 6px;
                box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
                .c-header{
                  font-family: Lato;
                  font-size: 32px;
                  font-weight: bold;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1.63;
                  letter-spacing: normal;
                  text-align: left;
                  color: #191536;
                }
                p{
                  font-family: Lato;
                  font-size: 16px;
                  font-weight: normal;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1.25;
                  letter-spacing: normal;
                  text-align: left;
                  color: #525252;
                }
            }
            .div1 {
                border-left: 8px solid #e9d6fe;
            }
            .div2 {
                border-left: 8px solid #ced7fd;
            }
        }

      }

      .outcome-header{
        font-family: Lato;
        font-size: 18px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.11;
        letter-spacing: normal;
        color: #898989;
      }

      .mental-health-outcome{
        .launch {
            position: absolute;
            top: 55%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 50px;
            font-weight: 900;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.88;
            letter-spacing: 1.5px;
            text-align: center;
            color: rgba(255, 255, 255, 0.92);
        }      
      }
      .isckon-outcome-main{
        position: relative;
        z-index: 1;
        .iskcon-app-feature-block{
          background-color: #F4F4F4;
          border-radius: 26px;
          &:before{
            content: url("../../../assets/images/caseStudy/iskcon/app-feature-ellipse.svg");
            position: absolute;
            left: -18%;
            top: -200px;
            z-index: -1;
          }
        }
        
        .iskcon-adapted-block{
          background-color: #F4F4F4;
          border-radius: 26px;
          position: relative;
          img{
            width: 30px;
            height: 30px;
          }
          &:after{
            content: url("../../../assets/images/caseStudy/iskcon/adapted-ellipse.svg");
            position: absolute;
            right: -26%;
            top: -250px;
            @media screen and (max-width: 767px){
              display: none;
            }
          }
        }
       
        .outcome-value-block-main{
          background: #F4F4F4;
          border-radius: 26px;
          position: relative;
  
          .value-card{
            background-color: $White-Color;
            box-shadow: 0px 6px 96px #00000021;
            border-radius: 20px;
            min-height: 185px;
            height: 100%;
            display: flex;
            align-items: start;
            justify-content: start;
            h4{
              color: $Iskcon-Theme-Color;
            }
  
            @media screen and (max-width: 767px){
              align-items: center;
            }
  
          }
        }
      }
    }
    /*Tabination End*/
  }
  
  @media screen and (max-width: 768px) {
    .c-container {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .c-div {
        width: 80%;
        &.div1 {
          margin-top: 2rem !important;
        }
        &.div2 {
          margin-top: 2rem !important;
        }
      }
    }
    .launch {
      font-size: 1.6rem !important;
      margin-top: 3.5rem;
    }
  }