.medstars-case-study-main{
  /*Banner Start*/
  .medstars-banner-main{
    position: relative;
    min-height: calc(100vh - 94px);
    background-image: url("../../../assets/images/caseStudy/medstars/medstar-banner-shape.svg");
    background-position: top;
    background-size: 100%;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    z-index: 1;
    .medstars-banner-content{
      position: relative;
      z-index: 1;
      h3{
        color: $Shawer-Theme-Color;
        font-size: 20px;
        line-height: 24px;
      }
      h2{
        color: #191536;
        font-size: 32px;
        line-height: 50px;
      }
      .banner-blocks{
        span{
          color: #B9B9B9;
          font-family: 'Lato-Bold', sans-serif;
          font-size: 12px;
          line-height: 15px;
          text-transform: uppercase;
        }
        p{
          color: #000000;
          font-family: 'Lato-Regular', sans-serif;
          font-size: 18px;
          line-height: 23px;
          margin-top: 10px;
        }
      }
    }
    &:before{
      content: url("../../../assets/images/caseStudy/shawer/shawer-dots.svg");
      position: absolute;
      left: 100px;
      bottom: 300px;
      z-index: -1;
    }
    &:after{
      content: url("../../../assets/images/caseStudy/shawer/shawer-dots.svg");
      position: absolute;
      right: 50px;
      bottom: 300px;
      z-index: -1;
    }
  }
  /*Banner End*/

  /*Tabination Start*/
  .medstars-tabination{
    background-color: #FBFBFB;
    .medstars-tab-list{
      li{
        border-bottom: 2px solid transparent;
        cursor: pointer;
        @media screen and (min-width: 768px){
          margin: 0 15px;
        }
        a{
          font-family: 'Lato-Bold', sans-serif;
          font-size: 14px;
          line-height: 18px;
          color: #A0A0A0;
          text-transform: uppercase;
          border-radius: 0;
          padding-bottom: 15px;
          &:hover {
            color: $Primary-Color;
          }
        }
        &:hover {
          background: transparent;
          color: $Primary-Color;
          border-bottom: 2px solid $Primary-Color;
        }
        .active{
          background: transparent;
          color: $Primary-Color;
          border-bottom: 2px solid $Primary-Color;
        }
      }
    }

    .medstars-about-content{
      position: relative;
      z-index: 1;
    }

    .medstars-challenge-main{
      position: relative;
      z-index: 1;

      .medstars-challenge-list{
        position: relative;
        li{
          position: relative;
          padding-left: 50px;

          &:before{
            content: '';
            position: absolute;
            left: 0;
            top: 15px;
            height: 15px;
            width: 15px;
            border-radius: 50%;
            background: #919900;
          }
          &:after{
            content: '';
            position: absolute;
            left: 5px;
           height: 70%;
            bottom: 0;
            margin: auto;
            width: 3px;
            background: #919900;
          }
          &:last-child{
            padding-bottom: 0 !important;
            &:after{
              display: none;
            }
          }
        }

        &:after{
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          left: 30px;
          height: 100%;
          border: 1px dashed #cccccc;
        }
      }

      &:after{
        content: url("../../../assets/images/caseStudy/medstars/medstar-ellipse.svg");
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: -1;
      }
      &:before{
        content: url("../../../assets/images/caseStudy/shawer/shawer-dots.svg");
        position: absolute;
        left: -50px;
        bottom: -50px;
        z-index: -1;
      }
    }

  }
  /*Tabination End*/
}
