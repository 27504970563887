.input-with-lable {
  input {
    background-color: $Input-Background-Color;
    outline: none;
    &:focus {
      outline: none;
      box-shadow: none;
    }
    &::placeholder {
      font-size: 16px;
      line-height: 13px;
      color: $Input-Placeholder-Color;
    }
  }
  @media screen and (max-width: 576px){
    input {
      &::placeholder {
        font-size: 14px;
      }
    }
  }
}
