.career-detail-main {
  .career-detail-banner {
    background-image: url("../../../assets/images/career-detail-bg.svg");
    background-repeat: repeat;
    background-position: bottom right;
    background-size: cover;
    .back {
      font-size: 15px;
      line-height: 15px;
      color: $White-Color;
      font-family: 'Lato-Bold', sans-serif;
    }
    .title{
      line-height: 60px;
      font-size: 20px;
      font-family: 'Lato-Bold', sans-serif;
      color: $White-Color;
    }
    .designation{
      font-size: 40px;
      line-height: 60px;
      font-family: 'Lato-Bold', sans-serif;
      color: $White-Color;
    }
    .location{
      font-size: 22px;
      line-height: 60px;
      color: $White-Color;
      font-family: 'Lato-Bold', sans-serif;
    }
  }
  .paragraph {
    font-size: 18px;
    line-height: 27px;
    color: $Dark-Secondary-Color;
    font-family: 'Lato-Regular', sans-serif;
    margin-bottom: 0 !important;
    padding: 20px 0;
  }
  .heading {
    font-size: 28px;
    line-height: 34px;
    font-family: 'Lato-Regular', sans-serif;
    color: $Black-Color;
  }
  ul {
    padding-left: 20px;
    li {
      color: $Dark-Secondary-Color;
      font-size: 18px;
      line-height: 27px;
      font-family: 'Lato-Regular', sans-serif;
      padding-top: 5px;
    }
  }
  .similar-job-title {
    font-size: 25px;
    line-height: 30px;
    font-family: 'Lato-Bold', sans-serif;
    color: $Primary-Color;
  }
  .responsive-location {
    display: none;
  }
  .responsive-share {
    display: none;
  }

  @media screen and (max-width: 576px){
    .career-detail-banner {
      .back {
        font-size: 12px;
        line-height: 10px;
      }
      .title{
        line-height: 42px;
        font-size: 12px;
      }
      .designation{
        font-size: 30px;
        line-height: 44px;
      }
      .location{
        display: none;
      }
      .responsive-location {
        display: block;
        font-size: 16px;
        line-height: 44px;
        color: $White-Color;
        font-family: 'Lato-Regular', sans-serif;
      }
      .responsive-share {
        display: block;
        width: 22px;
        height: 22px;
      }
    }
    .paragraph {
      font-size: 14px;
      line-height: 19px;
    }
    .heading {
      font-size: 18px;
      line-height: 22px;
    }
    ul {
      padding-left: 20px;
      li {
        font-size: 14px;
        line-height: 18px;
      }
    }
    .similar-job-title {
      font-size: 13px;
      line-height: 16px;
    }
  }
}
