/*Industries Card Start*/
.industries-card-main {
  position: relative;
  .industry-card-img-main {
    position: relative;
    .industry-card-img-content {
      position: absolute;
      left: 30px;
      bottom: 30px;

      h4 {
        font-family: 'Lato-Bold';
        font-size: 31px;
        line-height: 38px;
        color: $White-Color;
      }

      p {
        font-family: 'Lato-Regular';
        font-size: 20px;
        line-height: 24px;
        color: $White-Color;
        margin: 0;
      }
    }
    &:hover{
      cursor: pointer;
    }
  }

  .coming-soon-main{
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    background: rgba(0,0,0,0.5);
    margin: 0 15px;
    cursor: pointer;
  }
}

/*Industries Card End*/