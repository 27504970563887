.job-card-main {
  padding: 20px 10px;

  .right-side {
    white-space: nowrap;
    img {
      display: none;
    }
  }

  &:hover {
    cursor: pointer;
    background-color: $Dark-Pink-Color;

    span {
      color: $White-Color !important;
    }

    img {
      display: inline-block;
    }
  }

  @media screen and (max-width: 576px) {
    padding: 10px 5px;
  }
}
