.shawer-case-study-main{
  /*Banner Start*/
  .shawer-banner-main{
    position: relative;
    min-height: calc(100vh - 94px);
    /*background-image: url("../../../assets/images/caseStudy/shawer/shawer-banner-bg.png");
    background-repeat: no-repeat;
    background-position: top right;
    background-size: contain;*/
    display: flex;
    align-items: center;
    z-index: 1;
    &:after{
      content: url("../../../assets/images/caseStudy/shawer/shawer-dots.svg");
      position: absolute;
      left: -10%;
      top: 50px;
      z-index: 0;
      @media screen and (max-width: 767px){
        display: none;
      }
    }
    .iskcon-banner-content{
      position: relative;
      z-index: 1;
      h3{
        color: $Shawer-Theme-Color;
        font-size: 20px;
        line-height: 24px;
      }
      h2{
        color: #191536;
        font-size: 32px;
        line-height: 50px;
      }
      .banner-blocks{
        span{
          color: #B9B9B9;
          font-family: 'Lato-Bold', sans-serif;
          font-size: 12px;
          line-height: 15px;
          text-transform: uppercase;
        }
        p{
          color: #000000;
          font-family: 'Lato-Regular', sans-serif;
          font-size: 18px;
          line-height: 23px;
          margin-top: 10px;
        }
      }
    }
  }
  /*Banner End*/

  /*Tabination Start*/
  .shawer-tabination{
    background-color: #FBFBFB;
    .iskcon-tab-list{
      li{
        border-bottom: 2px solid transparent;
        cursor: pointer;
        @media screen and (min-width: 768px){
          margin: 0 15px;
        }
        a{
          font-family: 'Lato-Bold', sans-serif;
          font-size: 14px;
          line-height: 18px;
          color: #A0A0A0;
          text-transform: uppercase;
          border-radius: 0;
          padding-bottom: 15px;
          &:hover {
            color: $Primary-Color;
          }
        }
        &:hover {
          background: transparent;
          color: $Primary-Color;
          border-bottom: 2px solid $Primary-Color;
        }
        .active{
          background: transparent;
          color: $Primary-Color;
          border-bottom: 2px solid $Primary-Color;
        }
      }
    }

    .shawer-challenge-main{
      position: relative;
      background-image: url("../../../assets/images/caseStudy/shawer/large-semi-ellipse.svg");
      background-size: 25%;
      background-repeat: no-repeat;
      background-position: -10%;
    }

    .shawer-outcome-main{
      position: relative;
      background-image: url("../../../assets/images/caseStudy/shawer/outcome-ellipse.png"), url("../../../assets/images/caseStudy/shawer/white-ellipse.svg");
      background-repeat: no-repeat;
      background-position: 700px 100px , 0 900px;
      z-index: 1;

      .iskcon-app-feature-block{
        background-color: #F4F4F4;
        border-radius: 26px;
      }

      .iskcon-adapted-block{
        background-color: #F4F4F4;
        border-radius: 26px;
        position: relative;
        &:after{
          content: url("../../../assets/images/caseStudy/iskcon/adapted-ellipse.svg");
          position: absolute;
          right: -26%;
          top: -250px;
        }
      }

      .outcome-value-block-main{
        background: #F4F4F4;
        border-radius: 26px;
        position: relative;

        .value-card{
          background-color: $White-Color;
          box-shadow: 0px 6px 96px #00000021;
          border-radius: 20px;
          min-height: 185px;
          display: flex;
          align-items: start;
          justify-content: start;
          h4{
            color: #EF504C;
          }

        }
      }

      &:after{
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 200px;
        background-color: $White-Color;
        z-index: -1;
      }

      &:before{
        content: url("../../../assets/images/caseStudy/shawer/shawer-dots.svg");
        position: absolute;
        right: 50px;
        top: -100px;
        z-index: 0;
        @media screen and (max-width: 767px){
          display: none;
        }
      }
    }

    .shawer-experience{
      position: relative;
      background-image: url("../../../assets/images/caseStudy/shawer/dotted-ellipse.svg");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: -10%;
    }

    .shawer-screens{
      background-color: $Shawer-Theme-Color;
      background-image: url("../../../assets/images/caseStudy/shawer/screen-bg-ellipse.svg");
      background-size: 25%;
      background-repeat: no-repeat;
      background-position: -15% 100%;
      position: relative;
      z-index: 0;
      &:after{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 200px;
        width: 100%;
        background-color: $White-Color;
        z-index: -1;
      }
    }
  }
  /*Tabination End*/
}
