.iskcon-case-study-main{
  /*Banner Start*/
  .iskcon-banner-main{
    position: relative;
    min-height: calc(100vh - 94px);
    background-color: #F4F4F4;
    background-image: url("../../../assets/images/caseStudy/iskcon/iskcon-banner-bg.svg");
    background-repeat: no-repeat;
    background-position: top right;
    background-size: contain;
    display: flex;
    align-items: center;
    z-index: 1;
    &:after{
      content: url("../../../assets/images/caseStudy/argo/argo-banner-dots.svg");
      position: absolute;
      left: -10%;
      bottom: 0;
      z-index: 0;
      @media screen and (max-width: 767px){
        display: none;
      }
    }
    .iskcon-banner-content{
      position: relative;
      z-index: 1;
      h3{
        color: $Iskcon-Theme-Color;
        font-size: 20px;
        line-height: 24px;
      }
      h2{
        color: #191536;
        font-size: 32px;
        line-height: 50px;
      }
      .banner-blocks{
        span{
          color: #B9B9B9;
          font-family: 'Lato-Bold', sans-serif;
          font-size: 12px;
          line-height: 15px;
          text-transform: uppercase;
          margin-bottom: 10px;
        }
        p{
          color: #000000;
          font-family: 'Lato-Regular', sans-serif;
          font-size: 18px;
          line-height: 23px;
        }
      }
    }
  }
  /*Banner End*/

  /*Tabination Start*/
  .iskcon-tabination{
    .iskcon-tab-list{
      li{
        border-bottom: 2px solid transparent;
        cursor: pointer;
        @media screen and (min-width: 768px){
          margin: 0 15px;
        }
        a{
          font-family: 'Lato-Bold', sans-serif;
          font-size: 14px;
          line-height: 18px;
          color: #A0A0A0;
          text-transform: uppercase;
          border-radius: 0;
          padding-bottom: 15px;
          &:hover {
            color: $Iskcon-Theme-Color;
          }
        }
        &:hover {
          background: transparent;
          color: $Iskcon-Theme-Color;
          border-bottom: 2px solid $Iskcon-Theme-Color;
        }
        .active{
          background: transparent;
          color: $Iskcon-Theme-Color;
          border-bottom: 2px solid $Iskcon-Theme-Color;
        }
      }
    }

    .iskcon-about-content{
      position: relative;
      z-index: 1;
      &:before{
        content: url("../../../assets/images/caseStudy/iskcon/iskcon-about-ellipse.svg");
        position: absolute;
        left: -26%;
        top: -50px;
      }
      &:after{
        content: url("../../../assets/images/caseStudy/iskcon/iskcon-about-semi-ellipse.svg");
        position: absolute;
        right: -26%;
        top: -50px;
        z-index: -1;
        @media screen and (max-width: 767px){
          right: 0;
        }
      }
    }

    .iskcon-challenge-main{
      position: relative;
      z-index: 2;
    }

    .isckon-outcome-main{
      position: relative;
      z-index: 1;
      .iskcon-app-feature-block{
        background-color: #F4F4F4;
        border-radius: 26px;
        &:before{
          content: url("../../../assets/images/caseStudy/iskcon/app-feature-ellipse.svg");
          position: absolute;
          left: -18%;
          top: -200px;
          z-index: -1;
        }
      }

      .iskcon-adapted-block{
        background-color: #F4F4F4;
        border-radius: 26px;
        position: relative;
        img{
          width: 30px;
          height: 30px;
        }
        &:after{
          content: url("../../../assets/images/caseStudy/iskcon/adapted-ellipse.svg");
          position: absolute;
          right: -26%;
          top: -250px;
          @media screen and (max-width: 767px){
            display: none;
          }
        }
      }
      .outcome-value-block-main{
        background: #F4F4F4;
        border-radius: 26px;
        position: relative;

        .value-card{
          background-color: $White-Color;
          box-shadow: 0px 6px 96px #00000021;
          border-radius: 20px;
          min-height: 185px;
          height: 100%;
          display: flex;
          align-items: start;
          justify-content: start;
          h4{
            color: $Iskcon-Theme-Color;
          }

          @media screen and (max-width: 767px){
            align-items: center;
          }

        }
      }
    }
  }
  /*Tabination End*/
}
