.loader {
  z-index: 9;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: hsla(0,0%,100%,.7);
}

.loader-inner {
  bottom: 0;
  height: 100px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 100px;
}

.loader-line-wrap-wrap {
  animation: spin 2.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) infinite;
  box-sizing: border-box;
  height: 50px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  transform-origin: 50% 100%;
  width: 100px;
}

.loader-line-wrap {
  border: 4px solid;
  border-radius: 100%;
  box-sizing: border-box;
  height: 97px;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 97px;
  border-color: $Light-Primary-Color;
}

.loader-line-wrap-wrap:nth-child(1) {
  animation-delay: -0.1s;
}
.loader-line-wrap-wrap:nth-child(2) {
  animation-delay: -0.2s;
}
.loader-line-wrap-wrap:nth-child(3) {
  animation-delay: -0.3ms;
}

.loader-line-wrap-wrap:nth-child(1) .loader-line-wrap {

  height: 90px;
  width: 90px;
  top: 5px;
}
.loader-line-wrap-wrap:nth-child(2) .loader-line-wrap {

  height: 70px;
  width: 70px;
  top: 15px;
}
.loader-line-wrap-wrap:nth-child(3) .loader-line-wrap {
  height: 50px;
  width: 50px;
  top: 25px;
}

@keyframes spin {
  0%,
  15% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
