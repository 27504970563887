.blog-card  {
  .blog-card-img{
    width: 100%;
    transition: transform .5s ease;
    &:hover{
      transform: scale(1.05);
    }
  }
  .blog-card-content{
    p{
      font-size: 24px;
      line-height: 30px;
      font-family: 'Lato-Bold', sans-serif;
      margin: 0;
      @media screen and (max-width: 768px){
        font-size: 20px;
        line-height: 28px;
      }
    }

    span {
      font-size: 18px;
      line-height: 38px;
      font-family: 'Lato-Bold', sans-serif;
    }

    .dot {
      height: 5px;
      width: 5px;
      background-color: $Primary-Color;
      border-radius: 50%;
      display: inline-block;
      margin-bottom: 4px;
      margin-right: 10px;
      margin-left: 10px;
    }
  }

  &:hover{
    overflow: hidden;
    cursor: pointer;
  }
}
