.blog-page-main {
  .blog-banner-main{
    position: relative;
    min-height: 100vh;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-image: url("../../images/blog/blog-banner.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    .blog-banner {
      color: $White-Color;
      .banner-text{
        span {
          font-size: 20px;
          line-height: 24px;
          font-weight: bold;
        }
        p {
          font-size: 42px;
          line-height: 50px;
          font-weight: bold;
        }
      }
    }
  }

  .blog-page-cards-main{
    background-image: url("../../images/blog/blog-bg-image.png");
    background-repeat: no-repeat;
    background-position: 130% 150%;
    /*padding-bottom: 180px !important;*/
    .title {
      font-size: 36px;
      line-height: 44px;
      text-align: center;
      color: $Primary-Color;
      font-family: 'Lato-Bold', sans-serif;
    }
  }

  /*Media Query Start*/
  @media screen and (max-width: 576px){
    .blog-banner-main{
      min-height: 55vh;
      .blog-banner {
        span {
          font-size: 12px !important;
          line-height: 15px !important;
          font-weight: bold;
        }
        p {
          font-size: 24px !important;
          line-height: 50px !important;
          font-weight: bold;
        }
      }
    }
    .title {
      font-size: 24px !important;
      line-height: 30px !important;
    }
  }
  /*Media Query End*/
}
