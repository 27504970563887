.thankyou-main {
  h2 {
    font-size: 38px;
    line-height: 50px;
    color: $Primary-Color;
    font-family: 'Lato-Bold', sans-serif;
  }
  p {
    font-size: 20px;
    line-height: 28px;
    font-family: 'Lato-Regular', sans-serif;
    color: $Light-Grey-Color;
  }
  button {
    background-color: $Primary-Color;
    color: $White-Color;
    border-radius: 5px;
    padding: 5px 20px;
    font-size: 16px;
    line-height: 36px;
    font-family: 'Lato-Bold', sans-serif;
    outline: none;
    border: 0;
  }

  @media screen and (max-width: 576px){
    text-align: center;
    h2 {
      font-size: 24px;
    }
    p {
      font-size: 14px;
      line-height: 21px;
    }
    button {
      font-size: 12px;
      line-height: 14px;
      padding: 10px 20px;
    }
  }
}
