.how-help-you-main {
  background-color: $Sky-Color;

  .product-banner-main{
    background: transparent linear-gradient(56deg, #2B2748 0%, #1D183C 28%, #191536 100%) 0% 0% no-repeat padding-box;
    height: calc(100vh - 85px);
    @media screen and (min-width: 768px) and (max-width: 1024px){
     height: auto;
    }
    h2{
      span{
        color: #7d20db;
      }
    }

    .product-slider-main{
      width: 100%;
      height: calc(100vh - 200px);

      @media screen and (max-width: 767px){
        .custom-indicators{
          bottom: -10px;
        }
      }
      @media screen and (min-width: 768px){
        .custom-indicators{
          right: -300px;
          bottom: 50px;
        }
      }
      @media screen and (min-width: 768px) and (max-width: 1024px){
        min-height: 700px;
        height: 700px;
      }

      .product-banner-slider-content-main{
        /*background: red;*/
        /*display: inline-flex;
        flex-wrap: wrap;*/
      }

      .product-banner-rings{
        height: 55%;
        display: inline-flex;
        align-items: center;
        img{
          max-height: 250px;
          @media screen and (max-width:576px){
            max-height: 200px;
          }
        }


      }
    }

  .vert .carousel-item-next.carousel-item-left,
  .vert .carousel-item-prev.carousel-item-right {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  .vert .carousel-item-next,
  .vert .active.carousel-item-right {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  .vert .carousel-item-prev,
  .vert .active.carousel-item-left {
    -webkit-transform: translate3d(0,-100%, 0);
    transform: translate3d(0,-100%, 0);
  }

}

  .product-content-card{
    .product-icon-shadow{
      border-radius: 8px;
    }
    &:hover{
      .product-icon-shadow{
        box-shadow: 0 10px 20px 0 rgba(131, 58, 209, 0.2);
      }
    }
  }

  .happy-client-main{/*
    background: transparent linear-gradient(131deg, #F47721 0%, #8E2CCF 100%) 0% 0% no-repeat padding-box;
    background-position: 50% !important;*/
    background: linear-gradient(to left, #F47721 0%, #8E2CCF 60%, #ffffff 40%);
    position: relative;
    z-index: 1;

    @media screen and (max-width: 767px){
      background: transparent linear-gradient(131deg, #F47721 0%, #8E2CCF 100%) 0% 0% no-repeat padding-box;
    }

    .product-happy-client-slider-main{
      .happy-client-slider-btns{
        position: relative;
        padding: 0 15px;
        .carousel-control-prev{
          position: relative;
          top: unset;
          bottom: unset;
          right: unset;
          left: unset;
          background: $White-Color;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          opacity: 1;
          height: 40px;
          width: 40px;
          border-radius: 50%;
          box-shadow: 0 0 26px 0 rgba(0, 0, 0, 0.13);
          img{
            width: 20px;
          }
          img:first-child{
            display: inline-block;
          }
          img:last-child{
            display: none;
          }
          &:hover{
            img:first-child{
              display: none;
            }
            img:last-child{
              display: inline-block;
            }
          }
        }
        .carousel-control-next{
          position: relative;
          top: unset;
          bottom: unset;
          right: unset;
          left: unset;
          background: $White-Color;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          opacity: 1;
          height: 40px;
          width: 40px;
          border-radius: 50%;
          box-shadow: 0 0 26px 0 rgba(0, 0, 0, 0.13);
          img{
            width: 20px;
          }
          img:first-child{
            display: inline-block;
          }
          img:last-child{
            display: none;
          }
          &:hover{
            img:first-child{
              display: none;
            }
            img:last-child{
              display: inline-block;
            }
          }
        }
        @media screen and (max-width: 767px){
          position: absolute;
          top: 20px;
          right: 15px;
        }
      }

    }

    &:before{
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 200px;
      width: 100%;
      background: $White-Color;
      @media screen and (min-width: 768px){
        display: none;
      }
    }
  }

  .product-faq-main {
    .faq-nav-pills {
      @media screen and (max-width: 767px){
        white-space: nowrap;
        display: block;
        overflow: scroll;
        a{
          min-width: 250px;
        }
      }
      a {
        font-family: 'Lato-Regular', sans-serif;
        font-size: 20px;
        position: relative;
        padding: 15px 15px 15px 25px;
        margin-bottom: 15px;
        height: 70px;
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        color: $Black-Color;

        .faq-arrow {
          display: none;
        }

        &:before {
          content: '';
          position: absolute;
          left: 10px;
          top: 0;
          bottom: 0;
          height: 60%;
          width: 2px;
          background-color: #8E2CCF;
          margin: auto;
          display: none;
        }
      }

      .active {
        font-family: 'Lato-Regular', sans-serif;
        border-radius: 10px;
        box-shadow: 0px 0px 16px #0000001D;
        background-color: $White-Color;
        color: #8E2CCF;

        .faq-arrow {
          display: inline-flex !important;
        }

        &:before {
          display: block;
        }
      }
    }

    .faq-accordian{
      .card{
        border-radius: 0;
        border-right: none;
        border-left: none;
        border-color: #C7C7C7 !important;
        background-color: $White-Color;
        .card-header{
          border-radius: 0;
          background-color: $White-Color !important;
          padding: 25px 15px !important;
          h2{
            font-family: 'Lato-Regular', sans-serif;
            font-size: 20px;
            color: $Black-Color;
            cursor: pointer;
            i{
              font-size: 16px;
            }

          }
          h2[aria-expanded="true"]{
            i:first-child{
              display: none;
            }
          }
          h2[aria-expanded="false"]{
            i:last-child{
              display: none;
            }
          }

        }

        .card-body{
          font-family: 'Lato-Regular', sans-serif;
          font-size: 16px;
          line-height: 25px;
          color: $Primary-Color;
        }
      }
    }
  }
}