.submit-your-application-main {
  input,textarea{
    border-radius: 4px;
  }
  .title {
    font-size: 35px;
    line-height: 50px;
    font-family: 'Lato-Bold', sans-serif;
    color: $Primary-Color;
  }
  label {
    font-size: 18px;
    line-height: 29px;
    font-family: 'Lato-Bold', sans-serif;
    color: $Dark-Grayish-Navy-Color;
  }
  textarea {
    background-color: $Input-Background-Color;
    outline: none;
    border: 1px solid #ced4da;
    &:focus {
      outline: none;
      box-shadow: none;
    }
    &::placeholder {
      padding: 10px;
      font-size: 16px;
      line-height: 13px;
      color: $Input-Placeholder-Color;
    }
  }
  .country-code-dropdown {
    border: 1px solid #ced4da;
    background-color: $Input-Background-Color;
    font-size: 16px;
    line-height: 13px;
    color: $Input-Placeholder-Color;
    &:focus {
      outline: none;
      box-shadow: none;
    }
    &::placeholder {
      padding: 10px;
      font-size: 16px;
      line-height: 13px;
      color: rebeccapurple;
    }
  }

  .custom-choose-file{
    position: relative;
    height: 44px;
    display: inline-flex;
    align-items: center;
    background-color: $Input-Background-Color;
    outline: none;
    border: 1px solid #ced4da;
    border-radius: 4px;
    color: #171dbc;
    cursor: pointer;
    input{
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
    }
  }
  @media screen and (max-width: 576px){
    .title {
      font-size: 24px;
    }
    label {
      font-size: 16px;
    }
    textarea {
      &::placeholder {
        font-size: 14px;
      }
    }
    .country-code-dropdown {
      font-size: 14px;
    }
  }
}
