.not-found-main {
  p {
    font-size: 30px;
    line-height: 44px;
    font-family: 'Lato-Bold', sans-serif;
    margin-bottom: 40px;
  }

  @media screen and (max-width: 576px){
    p {
      font-size: 12px;
      line-height: 18px;
      text-align: center;
      margin-bottom: 150px;
    }
  }
}
