/*Footer Start*/
.footer-main{
  background-color: #191536;
  .footer-links{
    h5{
      color: $White-Color;
      font-size: 16px;
      line-height: 20px;
      font-weight: 400;
      font-family: 'Lato-Regular';
    }
    li{
      margin-bottom: 15px;
      a{
        color: $Light-White-Color;
        font-size: 14px;
        line-height: 20px;
        font-family: 'Lato-Regular';
        transition: 0.4s ease all;
        &:hover{
          color: $White-Color;
        }
      }
    }

  }
  .social-links{
    li{
      margin-right: 30px;
      transition: 0.5s ease all;
      &:last-child{
        margin-right: 0;
      }
      &:hover{
        transform: translateY(-5px);
      }
    }
  }
}

.copy-right{
  background-color: #0c0836;
}
/*Footer End*/