.about-us {
  .about-us-banner {
    background-color: $Primary-Color;
    min-height: calc(100vh - 89px);
    display: flex;
    align-items: center;
  }

  .tab {
    overflow: hidden;

    ul {
      display: block;
      text-align: center;
      margin-bottom: 0;
      white-space: nowrap;

      li {
        display: inline-block;
        list-style-type: none;
        padding: 0 30px;
        font-family: 'Lato-Bold', sans-serif;
        font-size: 16px;
        line-height: 54px;
        color: $Secondary-Color;
        margin: 0 15px;
        cursor: pointer;
      }

      .active {
        font-family: 'Lato-Black', sans-serif;
        color: $Primary-Color;
      }
    }
  }

  hr {
    margin: 0;
  }

  .about-us-section {
    h4 {
      color: $Purple-Color;
    }

    .our-value-main {
      background-color: $Sky-Color;

      .our-value-section-two {
        img {
          box-shadow: 0 10px 30px 0 rgba(131, 58, 209, 0.4);
          border-radius: 10px;
        }

        h6 {
          font-family: 'Lato-Bold', sans-serif;
          font-size: 18px;
          line-height: 22px;
          color: $Primary-Color;
          @media screen and (max-width: 768px) {
            font-size: 16px;
            line-height: 20px;
          }
        }

        p {
          font-family: 'Lato-Regular', sans-serif;
          font-size: 15px;
          line-height: 22px;
          color: $Dark-Secondary-Color;
          @media screen and (max-width: 768px) {
            font-size: 12px;
            line-height: 20px;
          }
        }
      }
    }
  }

  .diversity-section {
    .diversity-section-one {
      background-image: url("../../images/aboutus/diversity-b.svg");
      background-repeat: no-repeat;
      background-position: -7% 100%;

      .diversity-and-conclusion {
        display: flex;
        flex-direction: row;
        font-size: 18px;
        line-height: 38px;
        font-family: 'Lato-Bold', sans-serif;
        color: $Secondary-Color;
        width: 60%;

        &:before {
          content: "";
          flex: 1 1;
          border-bottom: 2px solid $Primary-Color;
          margin: auto;
        }

        &:before {
          margin-right: 10px
        }
      }

      .diversity-title {
        font-size: 42px;
        line-height: 52px;
        color: $Primary-Color;
        font-family: 'Lato-Bold', sans-serif;
      }

      .diversity-paragraph {
        font-size: 18px;
        line-height: 27px;
        font-family: 'Lato-Regular', sans-serif;
        color: $Dark-Secondary-Color;
      }
    }

    .diversity-section-three {
      /*margin-top: 100px;*/
      background-image: url("../../images/aboutus/diversity-d.svg");
      background-repeat: no-repeat;
      background-position: -500px;
      background-size: 100% 100%;

      h3 {
        font-size: 42px;
        line-height: 52px;
        color: $Primary-Color;
        font-family: 'Lato-Bold', sans-serif;
      }

      .aling-flex-end {
        align-self: flex-end;
      }
    }

    .diversity-section-four {
      background-color: $Sky-Color;
      text-align: center;
      /*margin-top: 100px;*/
      padding: 70px;

      h3 {
        font-size: 36px;
        line-height: 44px;
        color: $Black-Color;
        font-family: 'Lato-Bold', sans-serif;
      }

      p {
        font-size: 25px;
        line-height: 25px;
        color: $Dark-Secondary-Color;
        font-family: 'Lato-Regular', sans-serif;
      }
    }
  }

  .leader-ship-section {
    background-image:  url("../../images/aboutus/leadershipcardbg.svg"), url("../../images/aboutus/leadershipbg.svg");
    background-repeat: no-repeat;
    background-position: right top, 115% 108%;

    @media screen and (max-width: 767px){
      background-position: right top, 180% 108%;
    }

    .leadership {
      h2 {
        font-size: 42px;
        line-height: 50px;
        font-family: 'Lato-Bold', sans-serif;
        color: $Black-Color;
      }

      .leadership-card {
        margin-top: 30px;
        background: $White-Color;
        border-radius: 20px;
        padding: 40px;
        box-shadow: 0 3px 36px 0 rgba(0, 0, 0, 0.1);

        img {
          width: 158px;
          height: 158px;
        }

        .name {
          margin-top: 15px;
          font-size: 27px;
          line-height: 32px;
          font-family: 'Lato-Bold', sans-serif;
          color: $Primary-Color;
          margin-bottom: 0;
        }

        .designation {
          margin-top: 10px;
          font-size: 16px;
          line-height: 19px;
          font-family: 'Lato-Regular', sans-serif;
          color: $Primary-Color;
          margin-bottom: 0;
        }

        .intro-div {
          padding: 25px;

          .intro {
            font-size: 20px;
            line-height: 29px;
            font-family: 'Lato-Regular', sans-serif;
            color: $Primary-Color;
          }
        }
      }
    }

    .founder-story-section {
      margin-top: 100px;
      position: relative;
      z-index: 1;

      &:after{
        content: url("../../../assets/images/caseStudy/shawer/shawer-dots.svg");
        position: absolute;
        bottom: -100px;
        left: -100px;
        z-index: -1;
      }

      h3 {
        font-size: 36px;
        line-height: 38px;
        font-family: 'Lato-Bold', sans-serif;
        color: $Primary-Color;
      }

      p {
        margin-top: 20px;
        font-size: 20px;
        line-height: 30px;
        font-family: 'Lato-Regular', sans-serif;
        color: $Primary-Color;
      }

      .founder-story-card {
        background-color: $Sky-Color;
        border-radius: 15px;
        padding: 20px;

        .story {
          p {
            font-size: 20px;
            line-height: 29px;
            font-family: 'Lato-Regular', sans-serif;
            color: $Primary-Color;
          }

          h5 {
            font-size: 25px;
            line-height: 40px;
            font-family: 'Lato-Bold', sans-serif;
            color: $Primary-Color;
          }
        }
      }
    }

    .advisor-panel {
      margin-top: 50px;

      h3 {
        font-size: 36px;
        line-height: 38px;
        color: $Primary-Color;
        font-family: 'Lato-Bold', sans-serif;
      }

      .panel-data {
        p {
          margin-bottom: 0;
          font-size: 27px;
          line-height: 32px;
          color: $Primary-Color;
          font-family: 'Lato-Bold', sans-serif;
        }

        span {
          font-size: 20px;
          line-height: 24px;
          font-family: 'Lato-Regular', sans-serif;
          color: $Primary-Color;
        }
      }
    }
  }

  .career-section {
    position: relative;

    .working-at-connectlab {
      h3 {
        font-size: 34px;
        line-height: 41px;
        color: $Black-Color;
        font-family: 'Lato-Bold', sans-serif;
        margin-bottom: 20px;
      }

      p {
        font-size: 20px;
        line-height: 29px;
        color: $Primary-Color;
        font-family: 'Lato-Regular', sans-serif;
      }
    }

    .join-our-team{
      position: relative;
      &:after{
        content: url("../../../assets/images/aboutus/join-team-ellipse.svg");
        position: absolute;
        left: -18%;
        bottom: -200px;

        @media screen and (max-width: 576px){
          content: '';
          background-image: url("../../../assets/images/aboutus/join-team-ellipse.svg");
          background-size: 100% 100%;
          position: absolute;
          left: 0;
          right: 0;
          height: 200px;
          width: 200px;
        }
      }
    }

    .find-role {
      .find-role-custom-search {
        background-color: #F2F1F0;
        height: 56px;
        border-radius: 5px;
        width: 100%;
        border: none;
        outline: none;
        background-image: url("../../../assets/images/search.svg");
        background-repeat: no-repeat;
        background-position: 3%;
        font-family: 'Lato-Regular', sans-serif;
        font-size: 16px;
        line-height: 19px;
        padding-left: 50px;

        &::placeholder {
          color: $Primary-Color;
        }
      }
    }

    .location-current-position {
      .location-desktop {
        display: block;

        h4 {
          font-size: 20px;
          line-height: 24px;
          color: $Primary-Color;
          font-family: 'Lato-Bold', sans-serif;
          margin-bottom: 25px;
        }

        /*Custom Checkbox Start*/
        /* The container */
        .check-main {
          display: block;
          position: relative;
          padding-left: 50px;
          margin-bottom: 15px;
          cursor: pointer;
          font-size: 18px;
          font-family: 'Lato-Regular', sans-serif;
          color: $Dark-Secondary-Color;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          @media screen and (max-width: 991px){
            font-size: 14px;
          }
        }

        /* Hide the browser's default checkbox */
        .check-main input {
          position: absolute;
          opacity: 0;
          cursor: pointer;
          height: 0;
          width: 0;
        }

        /* Create a custom checkbox */
        .checkmark {
          position: absolute;
          top: 0;
          left: 0;
          height: 25px;
          width: 25px;
          background-color: #eee;
          border-radius: 5px;
        }

        /* On mouse-over, add a grey background color */
        .check-main:hover input ~ .checkmark {
          background-color: #ccc;
        }

        /* When the checkbox is checked, add a blue background */
        .check-main input:checked ~ .checkmark {
          background-color: #eeeeee;
        }

        /* Create the checkmark/indicator (hidden when not checked) */
        .checkmark:after {
          content: "";
          position: absolute;
          display: none;
        }

        /* Show the checkmark when checked */
        .check-main input:checked ~ .checkmark:after {
          display: block;
        }

        /* Style the checkmark/indicator */
        .check-main .checkmark:after {
          left: 9px;
          top: 3px;
          width: 7px;
          height: 14px;
          border: solid $Primary-Color;
          border-width: 0 2px 2px 0;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
        }
        /*Custom Checkbox Start*/
      }

      .location-mobile {
        display: none;
      }

      h3 {
        font-size: 25px;
        line-height: 30px;
        color: $Primary-Color;
        font-family: 'Lato-Bold', sans-serif;
      }
    }
  }

  @media screen and (max-width: 576px) {
    .tab {
      overflow: scroll;

      ul {
        li {
          padding: 10px;
          white-space: nowrap;
          font-size: 14px;
          line-height: 27px;
        }
      }
    }
    .about-us-section {
      h4 {
        font-size: 15px;
        line-height: 18px;
      }

      p {
        font-size: 14px;
        line-height: 24px;
      }

      .our-value {
        .our-value-section-two {
          img {
            width: 30%;
          }

          h6 {
            font-size: 12px;
            line-height: 15px;
          }

          p {
            font-size: 11px;
            line-height: 16px;
          }

        }
      }

      .global-reach {
        h3 {
          font-size: 16px;
          line-height: 19px;
        }
      }
    }
    .diversity-section {
      .diversity-section-one {
        background-position: -50% 100%;

        .diversity-and-conclusion {
          margin-top: 10px;
          font-size: 12px;
          line-height: 25px;
          width: 70%;
        }

        .diversity-title {
          font-size: 24px;
          line-height: 36px;
        }

        .diversity-paragraph {
          font-size: 14px;
          line-height: 23px;
        }
      }

      .diversity-section-three {
       /* margin-top: 100px;*/
        background-image: url("../../images/aboutus/diversity-d.svg"), url("../../images/aboutus/diversity-e.svg");
        background-repeat: no-repeat;
        background-position: -45% 0%, 100% 40%;

        h3 {
          font-size: 24px;
          line-height: 36px;
        }
      }

      .diversity-section-four {
        padding: 30px;

        h3 {
          font-size: 24px;
          line-height: 29px;
        }

        p {
          font-size: 16px;
          line-height: 22px;
        }
      }
    }
    .leader-ship-section {
      .leadership {
        h2 {
          margin-bottom: 70px;
          font-size: 24px;
          line-height: 29px;
        }

        .leadership-card {
          margin-top: 80px;
          padding: 20px;
          border-radius: 10px;

          .about-section {
            margin-top: -65px;
          }

          img {
            width: 85px;
            height: 85px;
          }

          .name {
            font-size: 14px;
            line-height: 15px;
          }

          .designation {
            font-size: 10px;
            line-height: 12px;
          }

          .intro-div {
            padding: 10px;
            text-align: center;

            .intro {
              font-size: 12px;
              line-height: 16px;
              margin-bottom: 0;
            }
          }
        }
      }

      .founder-story-section {
        text-align: center;
        margin-top: 50px !important;

        h3 {
          font-size: 24px !important;
          line-height: 14px !important;
        }

        p {
          font-size: 14px !important;
          line-height: 21px !important;
        }

        .founder-story-card {
          margin-top: 50px;
          text-align: left;

          .story {
            p {
              font-size: 12px !important;
              line-height: 19px !important;
            }

            h5 {
              font-size: 14px !important;
              line-height: 14px !important;
            }
          }
        }
      }

      .advisor-panel {
        h3 {
          font-size: 24px;
          line-height: 38px;
        }

        .panel-data {
          white-space: nowrap;

          p {
            margin-bottom: 0;
            font-size: 18px;
            line-height: 32px;
          }

          span {
            font-size: 16px;
            line-height: 19px;
          }
        }
      }
    }
    .career-section {
      .working-at-connectlab {
        h3 {
          font-size: 20px;
          line-height: 24px;
        }

        p {
          font-size: 15px;
          line-height: 26px;
        }
      }

      .find-role {
        h3 {
          font-size: 20px;
          line-height: 24px;
        }
      }

      .search-textbox {
        width: 80%;

        input {
          ::placeholder {
            font-size: 12px;
            line-height: 15px;
          }
        }
      }

      .location-current-position {
        .location-desktop {
          display: none;
        }

        .location-mobile {
          display: block;
          margin-bottom: 50px;

          /*input{
            margin-right: 15px;
          }*/

          .card {
            .card-header {
              background-color: $White-Color;
              font-size: 14px;
              line-height: 17px;
              font-family: 'Lato-Bold', sans-serif;
              color: $Primary-Color;
            }
          }
        }

        h3 {
          font-size: 14px;
          line-height: 17px;
        }

        h4 {
          font-size: 20px;
          line-height: 24px;
          color: $Primary-Color;
          font-family: 'Lato-Bold', sans-serif;
          margin-bottom: 20px;
        }
      }
    }
  }

  @media screen and (max-width: 1024px) {
    .about-us-banner {
      min-height: auto;
    }
  }
}
