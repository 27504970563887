.success-main{
  position: relative;
  /*background-image: url("../../../assets/images/succeess-bg-color.svg");
  background-position: center center;*/
  background: transparent linear-gradient(154deg, #EF504C 0%, #6333F8 100%) 0% 0% no-repeat padding-box;
  min-height: 100vh;
  display: flex;
  align-items: center;
  z-index: 0;
  &:after{
    content: url("../../../assets/images/success-bg.svg");
    position: absolute;
    right: -10%;
    bottom: 0;
    z-index: -1;
    @media screen and (max-width: 768px){
      display: none;
    }
  }
}