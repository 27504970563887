.question-card {
  background-color: $White-Color;
  border: 1px solid $Light-Grey-Question-Box;
  border-radius: 8px;
  padding: 20px;
  display: flex;
  align-items: center;
  min-height: 100px;
  margin-bottom: 20px;
  cursor: pointer;

  .media {
    width: 100%;
  }

  span {
    margin: 0;
    font-family: 'Lato-Bold', sans-serif;
    font-size: 16px !important;
    line-height: 22px !important;
    color: $Ship-Grey !important;
  }

  p {
    margin: 0;
    font-family: 'Lato-Regular', sans-serif;
    font-size: 16px !important;
    line-height: 22px !important;
    color: $Ship-Grey !important;
  }


}

.active-question-card {
  background-color: $White-Color;
  border: 1px solid $Light-Grey-Question-Box;
  border-radius: 8px;
  padding: 20px;
  display: flex;
  align-items: center;
  min-height: 100px;
  margin-bottom: 20px;
  box-shadow: 0 0 5px 0 rgba(131, 58, 209, 0.4);
  cursor: pointer;

  .media {
    width: 100%;
  }

  span {
    margin: 0;
    font-family: 'Lato-Bold', sans-serif !important;
    font-size: 16px !important;
    line-height: 22px !important;
    color: #462f89 !important;
  }

  p {
    margin: 0;
    font-family: 'Lato-Bold', sans-serif !important;
    font-size: 16px !important;
    line-height: 22px !important;
    color: #462f89 !important;
  }
}

.next-prev-ques-main {
  p,button{
    cursor: pointer;
  }
  .next-btn-main {

    button {
      img:last-child {
        display: none;
      }

      &:hover {
        img:first-child {
          display: none;
        }

        img:last-child {
          display: inline-block;
        }
      }
    }
  }
}
